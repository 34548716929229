var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------
var COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
export default function ButtonGroup(theme) {
    var rootStyle = function (ownerState) {
        var inheritColor = ownerState.color === 'inherit';
        var containedVariant = ownerState.variant === 'contained';
        var outlinedVariant = ownerState.variant === 'outlined';
        var textVariant = ownerState.variant === 'text';
        var softVariant = ownerState.variant === 'soft';
        var horizontalOrientation = ownerState.orientation === 'horizontal';
        var verticalOrientation = ownerState.orientation === 'vertical';
        var defaultStyle = {
            '& .MuiButtonGroup-grouped': {
                '&:not(:last-of-type)': __assign({}, (!outlinedVariant && __assign(__assign(__assign({ borderStyle: 'solid' }, (inheritColor && {
                    borderColor: alpha(theme.palette.grey[500], 0.32),
                })), (horizontalOrientation && {
                    borderWidth: '0px 1px 0px 0px',
                })), (verticalOrientation && {
                    borderWidth: '0px 0px 1px 0px',
                })))),
            },
        };
        var colorStyle = COLORS.map(function (color) { return ({
            '& .MuiButtonGroup-grouped': {
                '&:not(:last-of-type)': __assign({}, (!outlinedVariant && __assign({}, (ownerState.color === color && __assign(__assign(__assign({}, (containedVariant && {
                    borderColor: alpha(theme.palette[color].dark, 0.48),
                })), (textVariant && {
                    borderColor: alpha(theme.palette[color].main, 0.48),
                })), (softVariant && {
                    borderColor: alpha(theme.palette[color].dark, 0.24),
                })))))),
            },
        }); });
        var disabledState = {
            '& .MuiButtonGroup-grouped.Mui-disabled': {
                '&:not(:last-of-type)': {
                    borderColor: theme.palette.action.disabledBackground,
                },
            },
        };
        return __spreadArray(__spreadArray([], colorStyle, true), [defaultStyle, disabledState], false);
    };
    return {
        MuiButtonGroup: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: function (_a) {
                    var ownerState = _a.ownerState;
                    return rootStyle(ownerState);
                },
            },
        },
    };
}
